












import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OSurveyQuestion: () =>
      import(
        "@/components/organisms/event/modules/surveyModule/o-survey-question.vue"
      ),
  },

  setup() {
    return {};
  },
});
